import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="castellamonte1774">
<h1>Le Origini Familiari</h1>
<p>Antonio Carlo Napoleone Gallenga nasce a Parma il 4 novembre 1810.<br />
È figlio di Marianna Lombardini, donna parmigiana, e Celso Gallenga, soldato napoleonico di origine piemontese.</p>
<ImgCentro nome="castellamonte.PNG" alt="Castellamonte" didascalia="Il Castello di Castellamonte (TO)"/>
<ImgDestra nome="soldato.PNG" w="9" alt="Divisa dei soldati napoleonici" />
<p>Celso Gallenga nasce nel 1774 da una delle famiglie più in vista della zona.<br />
Il ramo da cui discende comprende molti professionisti in ambito medico, e in effetti anche il fratello maggiore, Giacomo, diventerà dottore. Per Celso invece si prefigura un destino diverso: infatti, in quanto secondogenito, viene avviato alla carriera ecclesiastica e rinchiuso in seminario. Tuttavia, comprendendo che si tratta di una vita che non fa per lui, nel 1795 il giovane Celso fugge da seminario, diventa giacobino e si arruola nell’esercito del generale Bonaparte.</p>
<p>Dopo aver combattuto sui campi di battaglia di tutta Europa, Celso, durante un presidio a Parma, conosce la giovane Marianna Lombardini, di cui si innamora e che sposa. Dal loro matrimonio nascono tre figlie e due figli, tra cui Antonio. Celso decide quindi di stabilirsi a Parma, dove svolge diversi mestieri (tra cui il fornaio) senza mai trovare soddisfazione nella vita sedentaria e un po’ ripetitiva che la città gli offre.</p>
<p>La sua irrequietezza accresce ancora di più a partire dal 1820, in seguito alla morte della moglie.<br />
Nel 1825, dopo aver affidato i figli al cognato, parte insieme ad altri patrioti per combattere nella guerra d’indipendenza della Grecia. Nonostante si distingua come soldato ardimentoso, specialmente nella battaglia di Negroponte, torna a casa deluso e insoddisfatto.</p>
<ImgCentro nome="libro.PNG" alt="Celso" didascalia="Libro di Celso"/>
<p>Dopo alcuni anni trascorsi nuovamente nella sua città adottiva, Parma, nel 1834 si mette in viaggio per seguire le orme del figlio Antonio, nel frattempo esiliato tra Europa e Africa. Purtroppo però non riuscirà mai a raggiungere Tangeri (Marocco), dove Antonio si trova in quel periodo, e si spegnerà a Livorno nello stesso anno.</p>
<h2>Curiosità</h2>
<p>Nel 1915, a Londra, viene pubblicato, su iniziativa di Guy Hardwin Gallenga, secondogenito di Antonio, l’autobiografia di Celso Gallenga dal titolo “The last soldier of Napoleon”. Il libro, quasi introvabile, è stato poi rinvenuto in una libreria antiquaria di Berkley (California) e tradotto in italiano da Sergio Musso.<br />
Questa versione in italiano, dal titolo <A href="https://books.google.it/books/about/L_ultimo_soldato_di_Napoleone.html?id=TL4XswEACAAJ&source=kp_book_description&redir_esc=y">“L’ultimo soldato di Napoleone”</A>, è stata poi pubblicata nel 2015 (a un secolo esatto di distanza dalla versione originale) per iniziativa dell’Associazione culturale “Terra Mia” di Castellamonte (TO).</p>
<h2>Link per un approfondire la figura di Celso Gallenga:</h2>
<p><A href="http://www.napoleonbonaparte.eu/celso-gallenga.html">“Qui ...”</A></p>
<p><A href="https://www.casabracco.it/celsogallenga">“...e qui!”</A></p>

	</PaginaTesto>
}